import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between pb-8 border-b" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_6 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_PatientConsultsDataTable = _resolveComponent("PatientConsultsDataTable")!
  const _component_ReviewSummary = _resolveComponent("ReviewSummary")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_PatientSupportingDocumentModal = _resolveComponent("PatientSupportingDocumentModal")!
  const _component_CreateConsultModal = _resolveComponent("CreateConsultModal")!
  const _component_AssignConsultModal = _resolveComponent("AssignConsultModal")!
  const _component_LockConsultModal = _resolveComponent("LockConsultModal")!
  const _component_RejectReasonModal = _resolveComponent("RejectReasonModal")!
  const _component_ResolveReasonModal = _resolveComponent("ResolveReasonModal")!
  const _component_MoveBackToScheduledModal = _resolveComponent("MoveBackToScheduledModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('custom.uhb.consult.consults')), 1)
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_SearchInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            size: "small",
            class: "w-48 text-sm",
            icon: "search",
            onChange: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "onChange"]),
          _createVNode(_component_BasePopover, { position: "left" }, {
            button: _withCtx(() => [
              (_ctx.isMajorPatient && _ctx.clinicTypeOptions.length > 0)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    class: "ml-4",
                    "left-icon": "add",
                    type: "button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.consult.assign')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            menu: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clinicTypeOptions, (clinicType) => {
                return (_openBlock(), _createBlock(_component_PopoverButton, {
                  key: clinicType.value,
                  onClick: ($event: any) => (_ctx.showAssignConsultModal(clinicType.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(clinicType.label), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ])), [
          [_directive_allow, 'consult:create']
        ])
      ]),
      _createVNode(_component_PatientConsultsDataTable, {
        patient: _ctx.patient,
        rows: _ctx.rows,
        "per-page": _ctx.perPage,
        page: _ctx.page,
        sort: _ctx.sort,
        total: _ctx.total,
        onShowScheduleConsultModal: _ctx.showScheduleConsultModal,
        onStartConsult: _ctx.startConsult,
        onResumeConsult: _ctx.resumeConsult,
        onStartReview: _ctx.startReview,
        onViewReviewSummary: _ctx.viewReviewSummary,
        onOpenRejectedReasonModal: _ctx.openRejectReasonModal,
        onOpenMoveBackToScheduledModal: _ctx.openMoveBackToScheduledModal,
        onOpenResolveReasonModal: _ctx.openResolveReasonModal,
        onViewSupportingDocument: _ctx.viewSupportingDocument,
        onClickUnlockConsult: _ctx.clickUnlockConsult
      }, null, 8, ["patient", "rows", "per-page", "page", "sort", "total", "onShowScheduleConsultModal", "onStartConsult", "onResumeConsult", "onStartReview", "onViewReviewSummary", "onOpenRejectedReasonModal", "onOpenMoveBackToScheduledModal", "onOpenResolveReasonModal", "onViewSupportingDocument", "onClickUnlockConsult"])
    ]),
    (_ctx.isViewAndCopyReviewSummaryVisible)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          class: "inline-block bg-white",
          "vertical-align": "center",
          onClose: _ctx.closeViewAndCopyReviewSummaryModel
        }, {
          default: _withCtx(() => [
            (_ctx.currentEpisodeOfCare)
              ? (_openBlock(), _createBlock(_component_ReviewSummary, {
                  key: 0,
                  patient: _ctx.selectedPatient,
                  "episode-of-care": _ctx.currentEpisodeOfCare,
                  "organisation-id": _ctx.organisationId,
                  onClose: _ctx.closeViewAndCopyReviewSummaryModel
                }, null, 8, ["patient", "episode-of-care", "organisation-id", "onClose"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.selectedDocumentId)
      ? (_openBlock(), _createBlock(_component_PatientSupportingDocumentModal, {
          key: 1,
          "document-id": _ctx.selectedDocumentId,
          onClose: _ctx.closeSupportingDocumentModal
        }, null, 8, ["document-id", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isAssignConsultModalVisible)
      ? _withDirectives((_openBlock(), _createBlock(_component_CreateConsultModal, {
          key: 2,
          title: _ctx.$t('custom.uhb.consult.assign'),
          "patient-id": _ctx.selectedPatient.id,
          "clinic-configuration-id": _ctx.clinicConfigurationId,
          "clinic-type-options": _ctx.clinicTypeOptions,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeAssignConsultModal($event)))
        }, null, 8, ["title", "patient-id", "clinic-configuration-id", "clinic-type-options"])), [
          [_directive_allow, 'consult:create']
        ])
      : _createCommentVNode("", true),
    (_ctx.isScheduleConsultModalVisible)
      ? _withDirectives((_openBlock(), _createBlock(_component_AssignConsultModal, {
          key: 3,
          title: _ctx.$t('custom.uhb.consult.schedule-consult'),
          patient: _ctx.selectedPatient,
          "episode-of-care": _ctx.currentEpisodeOfCare,
          "can-edit-patient-mrn": _ctx.canEditPatientMrn,
          "clinic-type-options": _ctx.clinicTypeOptions,
          errors: _ctx.scheduleErrors,
          onClose: _ctx.closeScheduleConsultModal,
          onSchedule: _cache[2] || (_cache[2] = ($event: any) => (_ctx.schedule($event)))
        }, null, 8, ["title", "patient", "episode-of-care", "can-edit-patient-mrn", "clinic-type-options", "errors", "onClose"])), [
          [_directive_allow, 'consult:schedule']
        ])
      : _createCommentVNode("", true),
    (_ctx.isUnlockModalVisible)
      ? (_openBlock(), _createBlock(_component_LockConsultModal, {
          key: 4,
          title: _ctx.$t('custom.uhb.review.unlock'),
          message: 
        _ctx.$t('custom.uhb.review.unlock-message', {
          user: _ctx.lockedByName
        })
      ,
          onClose: _ctx.closeUnlockModal,
          onExit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.unlockConsult(_ctx.selectedVirtualEncounterId)))
        }, null, 8, ["title", "message", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isRejectedReasonModalVisible && _ctx.currentEpisodeOfCare)
      ? (_openBlock(), _createBlock(_component_RejectReasonModal, {
          key: 5,
          patient: _ctx.selectedPatient,
          "episode-of-care": _ctx.currentEpisodeOfCare,
          error: _ctx.rejectReasonError,
          onMarkAsRejected: _ctx.markAsRejected,
          onUpdateErrorMessage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.rejectReasonError = $event)),
          onClose: _ctx.closeRejectReasonModal
        }, null, 8, ["patient", "episode-of-care", "error", "onMarkAsRejected", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isResolveReasonModalVisible && _ctx.currentEpisodeOfCare)
      ? (_openBlock(), _createBlock(_component_ResolveReasonModal, {
          key: 6,
          patient: _ctx.selectedPatient,
          "episode-of-care": _ctx.currentEpisodeOfCare,
          error: _ctx.resolveReasonError,
          onMarkAsResolved: _ctx.markAsResolved,
          onUpdateErrorMessage: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resolveReasonError = $event)),
          onClose: _ctx.closeResolveReasonModal
        }, null, 8, ["patient", "episode-of-care", "error", "onMarkAsResolved", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.enableMoveBackToScheduledModal)
      ? (_openBlock(), _createBlock(_component_MoveBackToScheduledModal, {
          key: 7,
          patient: _ctx.currentEpisodeOfCare?.patient,
          mrn: _ctx.currentEpisodeOfCare && _ctx.patientMrn,
          onMoveBackToScheduled: _ctx.moveBackToScheduled,
          onClose: _ctx.closeMoveBackToScheduledModal
        }, null, 8, ["patient", "mrn", "onMoveBackToScheduled", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}