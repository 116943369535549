
import { Options, Vue } from 'vue-class-component';
import {
  BaseButton,
  BasePagination,
  BasePopover,
  PopoverButton,
  ReviewSummary,
  SearchInput,
  BaseModal,
  LockConsultModal
} from '@/lib/components';
import PatientConsultsDataTable from '@/lib/components/DataTable/PatientConsultsDataTable.vue';
import {
  RejectReasonValue,
  ScheduleValue
} from '@/models/episode-of-care/encounter.model';

import {
  ClinicConfigurationsService,
  CodeableConceptService,
  ConsultService,
  EncounterService,
  EpisodeOfCareService,
  PatientFormSubmissionService
} from '@/services/api';
import {
  Clinic,
  ClinicConfiguration,
  PaginatedResponse,
  Patient,
  ResolveReasonValue
} from '@/models';
import {
  Action,
  EpisodeOfCareResponseData,
  EpisodeOfCareTableData
} from '@/models/episode-of-care/episode-of-care.model';
import { isMajorPatient } from '@/helpers/patient.helper';
import { CodeableConcept } from '@/lib';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { codeableConceptCategories } from '@/constants';
import { findCodeableConceptByCode } from '@/helpers/codeableConcepts.helper';
import debounce from 'lodash-es/debounce';
import axios from 'axios';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import CreateConsultModal from '@/views/worklists/CreateConsultModal.vue';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import ResolveReasonModal from '@/lib/components/Modals/ResolveReasonModal.vue';
import MoveBackToScheduledModal from '@/lib/components/Modals/MoveBackToScheduledModal.vue';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    ReviewSummary,
    BaseButton,
    BasePagination,
    BasePopover,
    PopoverButton,
    PatientConsultsDataTable,
    SearchInput,
    BaseModal,
    PatientSupportingDocumentModal,
    AssignConsultModal,
    CreateConsultModal,
    LockConsultModal,
    RejectReasonModal,
    ResolveReasonModal,
    MoveBackToScheduledModal
  }
})
export default class PatientConsultsPage extends Vue {
  patient!: Patient;
  organisationId: string;
  errors: { [key: string]: Array<string> } = {};
  codeableConceptService = new CodeableConceptService();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  // Clinic Configurations
  loadingClinicConfigurations = false;
  clinicConfigurations: ClinicConfiguration[] = [];

  clinicConfigurationId = '';
  // Consults
  rejectReasonError: string | null = null;
  resolvedConsultId = '';
  resolveReasonError: string | null = null;
  scheduleErrors: Record<string, string[]> = {};
  perPage = 10;
  total = 0;
  search = '';
  rows: EpisodeOfCareTableData[] = [];
  formSubmissionService = new PatientFormSubmissionService(this.patient.id);
  consultService = new ConsultService();
  encounterService = new EncounterService();
  viewLetter = false;
  isAssignConsultModalVisible = false;
  isRejectedReasonModalVisible = false;
  isResolveReasonModalVisible = false;
  isScheduleConsultModalVisible = false;
  enableMoveBackToScheduledModal = false;
  isUnlockModalVisible = false;
  isViewAndCopyReviewSummaryVisible = false;
  selectedDocumentId: string | null = null;
  episodeOfCareService = new EpisodeOfCareService();

  // MRN
  canEditPatientMrn = true;

  // View and copy ReviewSummary in plain test

  episodesOfCare: EpisodeOfCareResponseData[] | null = null;
  currentEpisodeOfCare?: EpisodeOfCareResponseData | null = null;
  selectedPatient: Patient | null = this.patient;

  selectedVirtualEncounterId = '';
  selectedFormSubmissionId = '';
  selectedClinic: Clinic = { id: '', name: '', clinic_configuration_id: '' };
  selectedConsultCompletedAt = '';
  selectedFormSubmissionClinician = '';
  lockedByName = '';

  // Statuses
  statuses: CodeableConcept[] = [];

  // encounterClasses
  encounterClasses: CodeableConcept[] = [];

  // Participants Types
  participantTypes: CodeableConcept[] = [];

  async mounted() {
    this.fetchClinicConfigurations();
    this.statuses = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.encounterStatus,
      this.$t('custom.uhb.episode-of-care.status-code-fetch-error')
    );

    this.encounterClasses = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.encounterClass,
      this.$t('custom.uhb.episode-of-care.encounter-class-fetch-error')
    );

    this.participantTypes = await this.fetchCodeableConceptsByCategory(
      codeableConceptCategories.participantType,
      this.$t('custom.uhb.episode-of-care.participant-type-fetch-error')
    );
    await this.fetchEpisodes();
    this.search = String(this.$route.query.search || '');

    this.$watch('organisationId', () => {
      this.search = String(this.$route.query.search || '');
      this.fetchClinicConfigurations();
    });
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchEpisodes();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  get currentOrganisationId(): string | null {
    return this.sessionStore.currentOrganisationId;
  }

  get clinicConfigurationService(): ClinicConfigurationsService | null {
    return this.organisationId
      ? new ClinicConfigurationsService(this.organisationId)
      : null;
  }

  get clinicTypeOptions() {
    return this.clinicConfigurations.map((clinicConfiguration) => ({
      value: clinicConfiguration.id,
      label: clinicConfiguration.name
    }));
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'created.time';
  }

  get isMajorPatient(): boolean {
    return isMajorPatient(this.patient);
  }

  // TODO: move these functions into a reusable location
  async getCodeableConceptByCategory(
    category: string
  ): Promise<CodeableConcept[]> {
    return (
      await this.codeableConceptService.index({
        params: {
          'filter[category]': category
        }
      })
    ).data;
  }

  // TODO: move these functions into a reusable location
  async fetchCodeableConceptsByCategory(
    category: string,
    errorMessage: string
  ): Promise<CodeableConcept[]> {
    let codeableConcepts: CodeableConcept[];
    try {
      this.loading = true;
      codeableConcepts = await this.getCodeableConceptByCategory(category);
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: errorMessage,
        label: error.response?.data?.message || ''
      });
    } finally {
      this.loading = false;
    }

    return codeableConcepts;
  }

  async fetchEpisodes() {
    try {
      this.loadingConsults = true;
      const participantTypeCodes = await this.fetchCodeableConceptsByCategory(
        codeableConceptCategories.participantType,
        this.$t('custom.uhb.episode-of-care.participant-type-fetch-error')
      );
      const createdParticipantCode = findCodeableConceptByCode(
        'CRE',
        participantTypeCodes
      );
      const virtualEncounterCode = findCodeableConceptByCode(
        'VR',
        this.encounterClasses
      );
      const ambulatoryEncounterCode = findCodeableConceptByCode(
        'AMB',
        this.encounterClasses
      );

      const response = (await this.episodeOfCareService.index({
        params: {
          page: this.page,
          sort: this.sort,
          perPage: this.perPage,
          include: 'ophthalmologyEpisodeOfCareDetail,media',
          'filter[patient]': this.patient.id,
          'filter[ownerOrProvider]': this.sessionStore.currentOrganisation.id,
          ...(this.search ? { 'filter[search]': this.search } : {})
        }
      })) as PaginatedResponse<EpisodeOfCareResponseData[]>;
      this.episodesOfCare = response.data;

      // Rejected consults should not appear in the PatientConsultsPage
      this.rows = this.episodesOfCare.map((row: EpisodeOfCareResponseData) => ({
        ...row,
        location: row.ophthalmology_details.location?.name,
        created: {
          time: row.created_at,
          user: row.encounters[0].participants.find(
            (participant) => participant?.type_id === createdParticipantCode?.id
          )?.user.name
        },
        scheduled_location: {
          location: null,
          title: row.ophthalmology_details.provider_title
        },
        clinic_configuration_name:
          row.ophthalmology_details.clinic_configuration_name,
        correspondence: row.status,
        review_overdue: row.review_overdue,
        consult_type: row.ophthalmology_details.consult_type,
        organisation_unit_owner_title: row.ophthalmology_details.owner_title,
        action: {
          id: row.id,
          virtualEncounterId: row.encounters.find(
            (encounter) => encounter.class === virtualEncounterCode?.id
          )?.id,
          ambulatoryEncounterId: row.encounters.find(
            (encounter) => encounter.class === ambulatoryEncounterCode?.id
          )?.id,
          status: row.status,
          formId: row.ophthalmology_details.form_id,
          formSubmissionId: row.encounters[0].form_submission_id,
          clinicId: row.ophthalmology_details.clinic.id,
          lockedBy: row.ophthalmology_details.locked_by,
          fileAttachmentId: row.file_attachment_id
        }
      }));
      this.total = response.meta.total;
    } catch (error) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.fetch-error'),
        label: error.response?.data?.message
      });
      if (!axios.isAxiosError(error)) {
        throw error;
      }
    } finally {
      this.loadingConsults = false;
    }
  }

  async fetchClinicConfigurations() {
    if (this.clinicConfigurationService) {
      try {
        this.loadingClinicConfigurations = true;
        this.clinicConfigurations = (
          await this.clinicConfigurationService.index()
        ).data;
      } catch (e) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      } finally {
        this.loadingClinicConfigurations = false;
      }
    }
  }

  // Rejected Modal
  openRejectReasonModal(id: string) {
    if (this.episodesOfCare) {
      this.currentEpisodeOfCare = this.episodesOfCare.find(
        (episodeOfCare) => id === episodeOfCare.id
      );
      this.isRejectedReasonModalVisible = true;
    }
  }

  closeRejectReasonModal() {
    this.isRejectedReasonModalVisible = false;
    this.currentEpisodeOfCare = null;
  }

  // Resolve Modal
  openResolveReasonModal(id: string) {
    if (this.episodesOfCare) {
      this.currentEpisodeOfCare = this.episodesOfCare.find(
        (episodeOfCare) => id === episodeOfCare.id
      );
      this.isResolveReasonModalVisible = true;
    }
  }

  closeResolveReasonModal() {
    this.isResolveReasonModalVisible = false;
    this.currentEpisodeOfCare = null;
  }

  showAssignConsultModal(id: string) {
    this.isAssignConsultModalVisible = true;
    this.clinicConfigurationId = id;
  }

  viewSupportingDocument(action: Action) {
    this.selectedDocumentId = action.fileAttachmentId;
  }

  closeSupportingDocumentModal() {
    this.selectedDocumentId = '';
  }

  // Schedule Consult Modal
  closeScheduleConsultModal() {
    this.isScheduleConsultModalVisible = false;
    this.clinicConfigurationId = '';
  }

  // Assign Consult Modal

  closeAssignConsultModal(fetchData = false) {
    this.isAssignConsultModalVisible = false;
    if (fetchData) {
      this.fetchEpisodes();
    }
  }

  async startConsult(action: Action) {
    if (this.episodesOfCare) {
      this.loadingAction = true;
      this.currentEpisodeOfCare = this.episodesOfCare.find(
        (episodeOfCare) => action.id === episodeOfCare.id
      );
      try {
        const response = await this.formSubmissionService.create({
          form_id: action.formId,
          organisational_unit_id: this.organisationId,
          encounter_id: this.currentEpisodeOfCare?.encounters[0].id
        });
        if (this.currentEpisodeOfCare) {
          const inProgressStatus = findCodeableConceptByCode(
            'in-progress',
            this.statuses
          );
          await this.encounterService.update(
            this.currentEpisodeOfCare.encounters[0].id,
            {
              status_id: inProgressStatus?.id || ''
            }
          );
        }
        await this.$router.push({
          name: 'patient-form',
          params: {
            organisationId: this.organisationId,
            patientId: this.patient.id,
            formSubmissionId: response.data.id
          }
        });
      } catch (e) {
        await this.notificationStore.addErrorNotification({
          title: `${this.$t('platform.error.error')}: ${e.message}`
        });
        this.loadingAction = false;
      }
    }
  }

  // Events

  resumeConsult(action: Action) {
    this.$router.push({
      name: 'patient-form',
      params: {
        organisationId: this.organisationId,
        patientId: this.patient.id,
        formSubmissionId: action.formSubmissionId
      }
    });
  }

  showScheduleConsultModal(id: string) {
    if (this.episodesOfCare) {
      this.isScheduleConsultModalVisible = true;
      this.currentEpisodeOfCare = this.episodesOfCare.find(
        (episodeOfCare) => id === episodeOfCare.id
      );

      // Disable the patient MRN input if the MRN is already set
      if (
        this.currentEpisodeOfCare &&
        this.currentEpisodeOfCare.patient_mrn_at_clinic_provider
      ) {
        this.canEditPatientMrn = false;
      }
    }
  }

  async startReview(action: Action) {
    if (action.virtualEncounterId) {
      await this.resumeReview(action.virtualEncounterId);
    } else {
      await this.createReview(action);
    }
    this.$router.push({
      name: 'patient-image-review',
      params: {
        organisationId: this.organisationId,
        patientId: this.patient.id,
        episodeOfCareId: action.id
      }
    });
  }

  async resumeReview(encounterId: string) {
    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );

    const reviewParticipantType = findCodeableConceptByCode(
      'REV',
      this.participantTypes
    );

    const data = {
      status_id: createdStatus?.id,
      locked: true,
      participants: [
        {
          user_id: this.currentUserId,
          participant_type_id: reviewParticipantType?.id,
          participant_type_code: reviewParticipantType?.code
        }
      ]
    };

    try {
      return await this.encounterService.update(encounterId, data);
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response?.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }

  async createReview(action: Action) {
    const createdStatus = findCodeableConceptByCode(
      'in-progress',
      this.statuses
    );
    const encounterClass = findCodeableConceptByCode(
      'VR',
      this.encounterClasses
    );

    const data = {
      patient_id: this.patient.id,
      encounter_class_id: encounterClass?.id,
      episode_of_care_id: action.id,
      clinic_id: action.clinicId,
      organisational_unit_id: this.currentOrganisationId,
      status_id: createdStatus?.id,
      locked: true,
      part_of: action.ambulatoryEncounterId
    };

    try {
      const virtualEncounter = await this.encounterService.create(data);
      return virtualEncounter.data;
    } catch (error) {
      if (error.response?.status === 422) {
        this.errors = error.response.data?.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.create-error'),
          label: error.response?.data?.message || ''
        });
      }
    }
    return;
  }

  viewReviewSummary(action: Action) {
    this.openViewAndCopyReviewSummaryModel(action);
  }

  closeViewAndCopyReviewSummaryModel() {
    this.currentEpisodeOfCare = null;
    this.isViewAndCopyReviewSummaryVisible = false;
  }

  openViewAndCopyReviewSummaryModel(action: Action) {
    this.currentEpisodeOfCare = this.episodesOfCare?.find(
      (episodeOfCare) => action.id === episodeOfCare.id
    );
    this.isViewAndCopyReviewSummaryVisible = true;
  }

  clickUnlockConsult(virtualEncounterId: string, lockedByName: string) {
    this.selectedVirtualEncounterId = virtualEncounterId;
    this.lockedByName = lockedByName;
    this.isUnlockModalVisible = true;
  }

  closeUnlockModal() {
    this.selectedVirtualEncounterId = '';
    this.lockedByName = '';
    this.isUnlockModalVisible = false;
  }

  async unlockConsult(encounterId: string) {
    await this.encounterService.update(encounterId, { locked: false });
    this.closeUnlockModal();
    return this.fetchEpisodes();
  }

  async schedule(scheduleDetails: ScheduleValue) {
    const scheduledStatus = findCodeableConceptByCode('triaged', this.statuses);
    try {
      const data = {
        status_id: scheduledStatus?.id ?? '',
        scheduled_at: scheduleDetails.scheduled_at,
        scheduled_location: scheduleDetails.scheduled_location,
        ...(scheduleDetails.patient_mrn_at_clinic_provider && {
          patient_mrn_at_clinic_provider:
            scheduleDetails.patient_mrn_at_clinic_provider
        })
      };
      if (this.currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          this.currentEpisodeOfCare.encounters[0].id,
          data
        );
        this.$emit('update-patient');
      }
      this.closeScheduleConsultModal();
      await this.fetchEpisodes();
    } catch (e) {
      if (e.response?.status === 422) {
        this.scheduleErrors = e.response.data?.errors;
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    const rejectedStatus = findCodeableConceptByCode(
      'cancelled',
      this.statuses
    );
    try {
      const data = {
        status_id: rejectedStatus?.id ?? '',
        extension: {
          rejected: {
            type: rejectedReason.selectedReason,
            reason: rejectedReason.additionalReason
          }
        }
      };
      if (this.currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          this.currentEpisodeOfCare.encounters[0].id,
          data
        );
        await this.fetchEpisodes();
      }
      this.closeRejectReasonModal();
    } catch (e) {
      if (e.response?.status === 422) {
        this.rejectReasonError = e.response.data?.message ?? '';
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  async markAsResolved(resolveReason: ResolveReasonValue) {
    const resolvedStatus = findCodeableConceptByCode(
      'resolved-rejected',
      this.statuses
    );
    try {
      const data = {
        status_id: resolvedStatus?.id ?? '',
        extension: {
          resolved: {
            type: resolveReason.selectedReason,
            reason: resolveReason.additionalReason
          }
        }
      };
      if (this.currentEpisodeOfCare?.encounters[0]) {
        await this.encounterService.update(
          this.currentEpisodeOfCare.encounters[0].id,
          data
        );
        await this.fetchEpisodes();
      }
      this.closeResolveReasonModal();
    } catch (e) {
      if (e.response?.status === 422) {
        this.resolveReasonError = e.response.data?.message ?? '';
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.update-error')
        });
      }
    }
  }

  async fetchConsult(consultId: string) {
    try {
      this.currentConsult = await this.consultService.fetch(consultId, {
        params: {
          include: 'patient,clinic,clinicConfiguration,review,form_submissions'
        }
      });

      if (this.currentConsult?.patient) {
        this.selectedPatient = this.currentConsult.patient;

        // Disable the patient MRN input if the MRN is already set
        if (this.currentConsult.patient_mrn_at_clinic_provider) {
          this.canEditPatientMrn = false;
        }

        this.patient_mrn_at_clinic_provider =
          this.currentConsult.patient_mrn_at_clinic_provider;
      }
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  openMoveBackToScheduledModal(id: string) {
    if (this.episodesOfCare) {
      this.currentEpisodeOfCare = this.episodesOfCare.find(
        (episodeOfCare) => id === episodeOfCare.id
      );
      this.enableMoveBackToScheduledModal = true;
    }
  }

  closeMoveBackToScheduledModal(fetchData = false) {
    this.enableMoveBackToScheduledModal = false;
    if (fetchData) {
      this.fetchEpisodes();
    }
  }

  get patientMrn(): string {
    if (this.isOwnerOrganisation) {
      return this.currentEpisodeOfCare.patient_mrn_at_clinic_owner;
    }
    return this.currentEpisodeOfCare.patient_mrn_at_clinic_provider;
  }

  async moveBackToScheduled() {
    const triagedStatus = findCodeableConceptByCode('triaged', this.statuses);
    try {
      const data = {
        status_id: triagedStatus?.id ?? ''
      };

      if (this.currentEpisodeOfCare?.encounters[0]) {
        const formSubmissionService = new PatientFormSubmissionService(
          this.currentEpisodeOfCare.patient.id
        );

        await formSubmissionService.delete(
          this.currentEpisodeOfCare.encounters[0].form_submission_id
        );
        await this.encounterService.update(
          this.currentEpisodeOfCare.encounters[0].id,
          data
        );

        this.fetchEpisodes();
        this.notificationStore.snackbar = {
          label: this.$t(
            'custom.uhb.consult.move-back-to-scheduled.snackbar-message'
          )
        };
      }
    } catch (e: any) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    } finally {
      this.closeMoveBackToScheduledModal();
    }
  }
}
